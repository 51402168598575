/* global google */
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import './App.css';

function App() {
  const [user, setUser] = useState({});

  function handleCallbackResponse(response) {
    // coming from Google documentation service
    console.log('Encoded JWT ID token: ' + response.credential);
    var userObject = jwtDecode(response.credential);
    console.log(userObject);
    // setUser(userObject);
    window.location.assign('newrequest.html');
    document.getElementById('signInDiv').hidden = true;
  }

  function handleSignOut(event) {
    setUser({});
    document.getElementById('signInDiv').hidden = false;
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '453909117338-ug3mucktarreoma7ovs1dap3n82vojdo.apps.googleusercontent.com',
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large',
    });
    google.accounts.id.prompt();
  }, []);
  //   If we have no user: sign in button;If we have a user: show the log out button
  return (
    <div className="App">
      <div className="content">
        {<div id="signInDiv"></div> /* Your content here. This could be other components or divs */}
      </div>
      {Object.keys(user).length !== 0 && (
        <button onClick={(e) => handleSignOut(e)}>Sign Out</button>
      )}
      {user && (
        <div>
          <img src={user.picture} alt=""></img>
          <h3>{user.name}</h3>
        </div>
      )}
    </div>
  );
}

export default App;
